import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import {
  BestRetirement,
  CoverBases,
  Hero,
  NavigateUnexpected,
  Options,
  PathForward,
} from 'page-components/advisor';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface AdvisorProps {}

const Advisor: FC<AdvisorProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background={colors.greenLight}>
      <Hero />
      <BestRetirement />
      <CoverBases />
      <PathForward />
      <NavigateUnexpected />
      <Options />
      <BottomCta
        heading="Let us guide you to the retirement you deserve."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default Advisor;

export const Head = () => (
  <SEO
    title="Meet your Dedicated Retirement Advisor | Retirable"
    description="Gain ongoing care and expertise from a fiduciary advisor to help you grow your savings, spend smarter, and navigate key decisions."
    imagePath="advisor-featured.png"
  />
);
